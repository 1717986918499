<template>
  <v-dialog v-model="dialogReceipt" persistent max-width="1100px">
    <v-card class="pa-0" elevation="2">
      <v-card-text class="pb-0">
        <v-form v-model="valid" ref="form" lazy-validation>
          <v-container fluid>
            <v-row class="pb-2">
                <v-col cols="12" class="pa-0">
                    <span class="display-2">
                        <v-icon left class="pb-1">mdi-apple-finder</v-icon>
                        {{ $vuetify.lang.t("$vuetify.gathering") }}
                    </span>
                </v-col>
            </v-row>
        
            <v-simple-table class="pa-0 mt-5" style="border: 1px solid #ccc" >
                <template v-slot:default>
                    <thead>
                        <tr style="border: 1px solid red">
                            <th rowspan="2">{{ $vuetify.lang.t("$vuetify.name") }}</th>
                            <th rowspan="2">{{ $vuetify.lang.t("$vuetify.type") }}</th>
                            <th rowspan="2">{{ $vuetify.lang.t("$vuetify.qty1") }}</th>
                            <th colspan="2">{{ $vuetify.lang.t("$vuetify.ThisArrival") }}</th>
                        </tr>
                        <tr>
                            <th>{{ $vuetify.lang.t("$vuetify.qty1") }}</th>
                            <th>{{ $vuetify.lang.t("$vuetify.GiveAway") }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in myObj.products" :key="item.id">
                            <td style="width: 500px">{{item.nick_name}}</td>
                            <td>{{$vuetify.lang.t("$vuetify.commodity")}}</td>
                            <td>{{item.number}}</td>
                            <td style="width: 180px">
                              <v-text-field
                                outlined
                                dense
                                style="color: black"
                                class="mt-4"
                                v-model="item.new_real_number"
                                @change="changeRealNum"
                                type="number"
                              />
                            </td>
                            <td style="width: 180px">
                              <v-text-field
                                outlined
                                dense
                                style="color: black"
                                class="mt-4"
                                v-model="item.give_number"
                                type="number"
                              />
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <v-checkbox
              v-model="completed"
              :label="$vuetify.lang.t('$vuetify.Completed1')"
            ></v-checkbox>
            {{this.changeRealNum()}}
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions class="pb-8">
        <v-spacer />
        <v-btn color="red darken-2" outlined @click="cancelForm">{{
          $vuetify.lang.t("$vuetify.cancel")
        }}</v-btn>
        <v-btn color="blue darken-2" @click="validate">{{
          $vuetify.lang.t("$vuetify.confirm")
        }}</v-btn>
        <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
    name: "ReceiptCRUD",
    props: {
      dialogReceipt: Boolean,
      myObj: Object,
      complete: Boolean,
    },
    data() {
      return {
        valid: false,
        completed: false,
      }
    },
    methods: {
      cancelForm() {
        this.resetValidation();
          this.$emit("update:dialogReceipt", false);
      },
      reset() {
          this.$refs.form.reset();
      },
      validate(){
        //this.$refs.form.validate() == true ? this.$emit("getreceipt", this.myObj) : console.log("false");
        if (this.$refs.form.validate() == true) {
          this.$emit("update:complete", this.completed);
          this.$emit("getreceipt", this.myObj);
        }
        else { 
          console.log("false")
        }
      },
      resetValidation() {
        this.$refs.form.resetValidation();
      },
      changeRealNum() {
        if (this.myObj.products) {
          this.completed = this.myObj.products.every(res => {
            return parseFloat(res.new_real_number) + res.real_number >= res.number
          })
        }
      },
    },
    watch: {
      dialogReceipt() {
        for (var i = 0; i < this.myObj.products.length; i++) {
          this.myObj.products[i].new_real_number = 0;
        }
      }
    },
}
</script>


